'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function createSchema(schema) {
  return schema;
}
var t = createSchema;

exports.createSchema = createSchema;
exports.t = t;
